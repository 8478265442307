import React from 'react'
import configuredAxios from 'lib/axios'
import { proxyUrl } from 'lib/utils/request'

import { ReactQueryConfigProvider } from 'react-query'

/**
 * This config sets up the Provider to proxy `react-query` requests _by default_
 * i.e. we no longer need to build a proxied url for API calls :tada:
 */
const reactQueryConfig = {
  queries: {
    queryFn: async (key, options = {}) => {
      if (!key) return
      let data = {}

      // TODO: Calling services directly only works currently as the Animal services allow requests from any services (*) - this may break in future
      // if they start allowing requests from specific domains only. Also, it's best to use UI proxy to proxy requests as this will handle SSO (a user
      // is redirected back through UI proxy so that the cached token is cleared). We are only calling services directly so that we can handle large
      // payload responses.
      if (options?.idToken) {
        const headers = {
          Authorization: `Bearer ${options.idToken.token}`,
          'Content-Type': 'application/json',
        }
        data = await configuredAxios.get(key, {
          headers: headers,
          withCredentials: false,
        })
      } else {
        data = await configuredAxios.get(proxyUrl({ link: key }))
      }

      return data?.data
    },
  },
}

const ConfiguredReactQueryProvider = ({ children }) => (
  <ReactQueryConfigProvider config={reactQueryConfig}>
    {children}
  </ReactQueryConfigProvider>
)

export default ConfiguredReactQueryProvider
