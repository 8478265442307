import { combineReducers } from 'redux'

import { customerReducer as customer } from 'lib/components/ReduxCustomer'
import animalScenarios from 'reducers/animalScenariosReducer'
import idToken from 'reducers/idTokenReducer'
import loggedIn from 'lib/reducers/loggedInReducer'
import organisation from 'reducers/organisationReducer'
import profile from 'reducers/profileReducer'
import conflicts from 'reducers/conflictsReducer'

export default combineReducers({
  animalScenarios,
  conflicts,
  customer,
  idToken,
  loggedIn,
  organisation,
  profile,
})
