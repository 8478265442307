import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import axios from 'lib/axios'

const FeatureToggles = createContext({})

const FeatureTogglesProvider = ({ children }) => {
  const [featureToggles, setFeatureToggles] = useState({})

  const fetchFeatureToggles = async () => {
    try {
      const response = await axios.get('./featureToggles.json')
      return response.data
    } catch {
      return {}
    }
  }

  useEffect(() => {
    fetchFeatureToggles().then(data => {
      setFeatureToggles(data)
    })
  }, [])

  const setToggle = useCallback(
    (toggleName, toggleValue) => {
      setFeatureToggles({
        ...featureToggles,
        [toggleName]: toggleValue,
      })
    },
    [featureToggles]
  )

  return (
    <FeatureToggles.Provider value={{ featureToggles, setToggle }}>
      {children}
    </FeatureToggles.Provider>
  )
}

const useFeatureToggles = () => {
  return useContext(FeatureToggles)
}

export { FeatureTogglesProvider, useFeatureToggles }
