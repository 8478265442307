import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles'

const SectionHeading = ({ children, title, noMargin = false, className }) => {
  return (
    <div
      className={classnames(styles.sectionHeadingWrapper, className, {
        [styles.noMargin]: noMargin,
      })}
    >
      <h4 className={styles.sectionHeading}>{title}</h4>
      {children ? <div className={styles.childrenWrapper}>{children}</div> : null}
    </div>
  )
}

SectionHeading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default SectionHeading
