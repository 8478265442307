import queryString from 'query-string'
import axios from 'lib/axios'

const getMultipleLinks = (_key, links, options = {}) => {
  return Promise.all(
    links.map(link => {
      // TODO: Calling services directly only works currently as the Animal services allow requests from any services (*) - this may break in future
      // if they start allowing requests from specific domains only. Also, it's best to use UI proxy to proxy requests as this will handle SSO (a user
      // is redirected back through UI proxy so that the cached token is cleared). We are only calling services directly so that we can handle large
      // payload responses.
      const reqOptions = options?.idToken
        ? {
            headers: {
              Authorization: `Bearer ${options.idToken.token}`,
              'Content-Type': 'application/json',
            },
            withCredentials: false,
          }
        : {}
      const reqLink = options?.idToken ? link : proxyUrl({ link })
      return axios.get(reqLink, reqOptions).then(response => response.data)
    })
  )
}

function proxyUrl({ link, params }) {
  const PROXY_ENDPOINT = `${global.config.UI_PROXY_ENDPOINT}/api/proxy`
  let urlToProxy = params ? `${link}?${queryString.stringify(params)}` : link

  return `${PROXY_ENDPOINT}/${encodeURIComponent(urlToProxy)}`
}

function url({ link, params }) {
  return params ? `${link}?${queryString.stringify(params)}` : link
}

export { getMultipleLinks, proxyUrl, url }
