import React from 'react'
import { connect } from 'react-redux'
import { ApolloProvider } from '@apollo/react-hooks'
import { apolloClient } from 'lib/utils/apolloClient'

import dataFaceIntrospectionQueryResultData from '../../../config/dataFaceFragmentTypes'
import fulfillmentIntrospectionQueryResultData from '../../../config/fulfillmentFragmentTypes'

let apolloClients = {
  baseClient: null,
  dataFaceClient: null,
  eventTimelineClient: null,
  sampleManagementClient: null,
}

const getApolloClient = () => {
  return apolloClients
}

const setupClients = idToken => {
  let baseClient = apolloClient(
    `${global.config.LAB_FULFILLMENT_SERVICE_ENDPOINT}/api`,
    idToken,
    fulfillmentIntrospectionQueryResultData
  )
  let dataFaceClient = apolloClient(
    `${global.config.DATA_FACE_ENDPOINT}/api`,
    idToken,
    dataFaceIntrospectionQueryResultData
  )
  let eventTimelineClient = apolloClient(
    `${global.config.EVENT_TIMELINE_SERVICE_ENDPOINT}/api/events`,
    idToken
  )
  let sampleManagementClient = apolloClient(
    `${global.config.SAMPLE_MANAGEMENT_SERVICE_ENDPOINT}/api`,
    idToken
  )
  let serviceInventoryClient = apolloClient(
    `${global.config.SERVICE_INVENTORY_SERVICE_ENDPOINT}/api`,
    idToken
  )

  apolloClients = {
    baseClient,
    dataFaceClient,
    eventTimelineClient,
    sampleManagementClient,
    serviceInventoryClient,
  }
  return apolloClients
}

const ApolloClientWrapper = ({ children, idToken }) => {
  let { baseClient } = setupClients(idToken)
  return baseClient ? (
    <ApolloProvider client={baseClient}>{children}</ApolloProvider>
  ) : null
}

export { ApolloClientWrapper, getApolloClient }
export default connect(state => {
  return {
    idToken: state.idToken.data,
  }
})(ApolloClientWrapper)
