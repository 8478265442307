import React, { Suspense, lazy } from 'react'
import queryString from 'query-string'

import { Redirect, Route, Switch, generatePath } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import RequestWrapper from '@licnz/react-request-wrapper'

const SampleProperties = lazy(() =>
  import(
    /* webpackChunkName: 'SampleProperties' */ 'components/AnimalSearchResultDetail/SampleProperties'
  )
)
const AnimalSearchResultDetail = lazy(() =>
  import(
    /* webpackChunkName: 'AnimalSearchResultDetail' */ 'components/AnimalSearchResultDetail'
  )
)
const AnimalSearchResults = lazy(() =>
  import(/* webpackChunkName: 'AnimalSearchResults' */ 'components/AnimalSearchResults')
)
const ConflictInvestigation = lazy(() =>
  import(
    /* webpackChunkName: 'ConflictInvestigation' */ 'components/ConflictInvestigation'
  )
)
const CreateWorkOrder = lazy(() =>
  import(/* webpackChunkName: 'CreateWorkOrder' */ 'components/CreateWorkOrder')
)
const FulfillmentOverview = lazy(() =>
  import(/* webpackChunkName: 'FulfillmentOverview' */ 'components/FulfillmentOverview')
)
const GenomeConflict = lazy(() =>
  import(/* webpackChunkName: 'GenomeConflict' */ 'components/GenomeConflict')
)
const GenomeConflicts = lazy(() =>
  import(/* webpackChunkName: 'GenomeConflicts' */ 'components/GenomeConflicts')
)
const HerdAssessment = lazy(() =>
  import(/* webpackChunkName: 'HerdAssessment' */ 'components/HerdAssessment')
)
const KPIController = lazy(() =>
  import(/* webpackChunkName: 'KPIController' */ 'components/KPIController')
)
const KTPSignOff = lazy(() =>
  import(/* webpackChunkName: 'KTPSignOff' */ 'components/LabOrderParentage/KTPSignOff')
)
const LabOrderController = lazy(() =>
  import(/* webpackChunkName: 'LabOrderController' */ 'components/LabOrderController')
)
const LabOrders = lazy(() =>
  import(/* webpackChunkName: 'LabOrders' */ 'components/LabOrders')
)
const NewTestCase = lazy(() =>
  import(/* webpackChunkName: 'NewTestCase' */ 'components/NewTestCase')
)
const ParentageMatchingController = lazy(() =>
  import(
    /* webpackChunkName: 'ParentageMatchingController' */ 'components/ParentageMatchingController'
  )
)
const ProfileOverview = lazy(() =>
  import(
    /* webpackChunkName: 'ProfileOverview' */ 'components/FulfillmentOverview/ProfileOverview'
  )
)
const ModifySample = lazy(() =>
  import(/* webpackChunkName: 'ModifySample' */ 'components/ModifySample')
)
const ProfileComparison = lazy(() =>
  import(/* webpackChunkName: 'ProfileComparison' */ 'components/ProfileComparison')
)
const RecordedParentage = lazy(() =>
  import(
    /* webpackChunkName: 'RecordedParentage' */ 'components/LabOrderParentage/RecordedParentage'
  )
)
const ResampleRequests = lazy(() =>
  import(/* webpackChunkName: 'ResampleRequests' */ 'components/ResampleRequests')
)
const RetrospectiveAnalysis = lazy(() =>
  import(
    /* webpackChunkName: 'RetrospectiveAnalysis' */ 'components/RetrospectiveAnalysis'
  )
)
const RetrospectiveAnalysisReport = lazy(() =>
  import(
    /* webpackChunkName: 'RetrospectiveAnalysisReport' */ 'components/RetrospectiveAnalysisReport'
  )
)
const StandaloneAnalysisReport = lazy(() =>
  import(
    /* webpackChunkName: 'StandaloneAnalysisReport' */ 'components/StandaloneAnalysisReport'
  )
)
const TestCase = lazy(() =>
  import(/* webpackChunkName: 'TestCase' */ 'components/TestCase')
)
const TestCases = lazy(() =>
  import(/* webpackChunkName: 'TestCases' */ 'components/TestCases')
)
const WorkOrderController = lazy(() =>
  import(/* webpackChunkName: 'WorkOrderController' */ 'components/WorkOrderController')
)
const WorkOrders = lazy(() =>
  import(/* webpackChunkName: 'WorkOrders' */ 'components/WorkOrders')
)

/**
 * A utility function for working with React Router paths. It allows us to both define
 * routes and build valid links to those routes with the same function, making it easier
 * to link to, search for, and refactor our routes (no more magic strings!)
 *
 * When called with only a `path`, it will return that path. This is useful when defining
 * a route, or creating a link to a simple, non-parameterised route.
 *
 * When called with `routeParams`, it will return a link to the path, substituting the
 * route param values for the placeholders in the path.
 *
 * When called with `queryParams`, it will return a link to the path, including a
 * queryString containing the query params.
 *
 */
const buildLink = ({ path, routeParams, queryParams }) => {
  let pathname = routeParams ? generatePath(path, routeParams) : path
  let search = queryParams ? `?${queryString.stringify(queryParams)}` : ''

  return `${pathname}${search}`
}

// See `buildLink` for valid `args`
const candidateParentExtractPath = args =>
  buildLink({
    ...args,
    path: '/lab_orders/:labOrderId/candidate_parent_extract',
  })
const labOrderParentageMatchingPath = args =>
  buildLink({
    ...args,
    path: '/lab_orders/:labOrderId/:matchType',
  })
const ktpSignOffPath = args =>
  buildLink({
    ...args,
    path: '/lab_orders/:labOrderId/ktp_sign_off',
  })
const labOrderRecordedParentagePath = args =>
  buildLink({
    ...args,
    path: '/lab_orders/:labOrderId/recorded_parentage',
  })
const labOrderProfileConflictPath = args =>
  buildLink({
    ...args,
    path: '/lab_orders/:labOrderId/conflict_resolution/:investigationId',
  })
const labOrderHerdAssessmentPath = args =>
  buildLink({
    ...args,
    path: '/lab_orders/:labOrderId/herd_assessment',
  })
const labOrderPath = args => buildLink({ ...args, path: '/lab_orders/:labOrderId' })
const modifySamplePath = args =>
  buildLink({
    ...args,
    path: '/animal_search/animal_details/sample_properties/modify_sample',
  })
const workOrderCreatePath = args => buildLink({ ...args, path: '/retrospective/create' })
const retrospectiveAnalysisReportPath = args =>
  buildLink({ ...args, path: '/retrospective/:workOrderId/report' })
const retrospectiveAnalysisPath = args =>
  buildLink({ ...args, path: '/retrospective/:workOrderId' })
const modifyWorkOrderCostPath = args =>
  buildLink({ ...args, path: '/work_orders/:workOrderId/modify_cost' })
const workOrderPath = args => buildLink({ ...args, path: '/work_orders/:workOrderId' })
const workOrderHerdAssessmentPath = args =>
  buildLink({ ...args, path: '/work_orders/:workOrderId/herd_assessment' })
const workOrdersPath = args => buildLink({ ...args, path: '/work_orders' })
const workOrderLabOrderPath = args =>
  buildLink({ ...args, path: '/work_orders/:workOrderId/lab_orders/:labOrderId' })
const fulfillmentOverviewPath = args => buildLink({ ...args, path: '/fulfillment' })
const fulfillmentOverviewHerdAssessmentPath = args =>
  buildLink({ ...args, path: '/fulfillment/herd_assessment' })
const profileOverviewPath = args => buildLink({ ...args, path: '/fulfillment/profiles' })
const kpisPath = args => buildLink({ ...args, path: '/kpis/:kpiType?' })
const genomeConflictsPath = args => buildLink({ ...args, path: '/genome_conflicts' })
const genomeConflictPath = args => buildLink({ ...args, path: '/genome_conflicts/:id' })
const animalSearchResultPath = args =>
  buildLink({ ...args, path: '/animal_search/animal_details' })
const animalSearchPath = args => buildLink({ ...args, path: '/animal_search' })
const labOrdersPath = args => buildLink({ ...args, path: '/lab_orders' })
const profileComparisonPath = args =>
  buildLink({ ...args, path: '/profile_comparison/:animalLink?' })

const testCaseCreatePath = args => buildLink({ ...args, path: '/test_cases/new' })
const testCasePath = args => buildLink({ ...args, path: '/test_cases/:identifier' })
const testCasesPath = args => buildLink({ ...args, path: '/test_cases' })
const resampleRequestsPath = args => buildLink({ ...args, path: '/resample_requests' })
const samplePropertiesPath = args =>
  buildLink({
    ...args,
    path: '/animal_search/animal_details/sample_properties',
  })
const standaloneAnalysisReportPath = args =>
  buildLink({ ...args, path: '/work_orders/:workOrderId/report' })

/*
 *  We have two types of routes here:
 *    1. Route - a standard (public) client side route.
 *    2. ProtectedRoute - a custom wrapper on the standard Route component.
 *  The ProtectedRoute should be used for all routes that require the user to be
 *  logged in. It checks the current loggedIn status and either renders the
 *  component at this route, or redirects back to the `/guest` route.
 */

const Routes = () => (
  <Suspense fallback={<RequestWrapper loading={true} />}>
    <Switch>
      <ProtectedRoute
        path={labOrderProfileConflictPath()}
        component={ConflictInvestigation}
      />
      <ProtectedRoute path={ktpSignOffPath()} component={KTPSignOff} />
      <ProtectedRoute
        exact
        path={candidateParentExtractPath()}
        component={props => (
          <HerdAssessment {...props} title='Candidate parent extract' />
        )}
      />
      <ProtectedRoute
        exact
        path={labOrderHerdAssessmentPath()}
        component={props => <HerdAssessment {...props} title='Herd assessment' />}
      />
      <ProtectedRoute
        path={labOrderRecordedParentagePath()}
        component={RecordedParentage}
      />
      <ProtectedRoute
        exact
        path={labOrderParentageMatchingPath()}
        component={ParentageMatchingController}
      />
      <ProtectedRoute path={modifySamplePath()} component={ModifySample} />
      <ProtectedRoute exact path={workOrderCreatePath()} component={CreateWorkOrder} />
      <ProtectedRoute
        exact
        path={retrospectiveAnalysisReportPath()}
        component={RetrospectiveAnalysisReport}
      />
      <ProtectedRoute
        exact
        path={retrospectiveAnalysisPath()}
        component={RetrospectiveAnalysis}
      />
      <ProtectedRoute path={samplePropertiesPath()} component={SampleProperties} />
      <ProtectedRoute
        exact
        path={workOrderLabOrderPath()}
        component={LabOrderController}
      />
      <ProtectedRoute exact path={workOrderPath()} component={WorkOrderController} />
      <ProtectedRoute
        exact
        path={modifyWorkOrderCostPath()}
        component={WorkOrderController}
      />
      <ProtectedRoute exact path={workOrdersPath()} component={WorkOrders} />
      <ProtectedRoute
        exact
        path={workOrderHerdAssessmentPath()}
        component={props => <HerdAssessment {...props} title='Herd Assessment' />}
      />
      <ProtectedRoute
        exact
        path={fulfillmentOverviewPath()}
        component={FulfillmentOverview}
      />
      <ProtectedRoute
        exact
        path={fulfillmentOverviewHerdAssessmentPath()}
        component={props => <HerdAssessment {...props} title='Herd Assessment' />}
      />
      <ProtectedRoute exact path={profileOverviewPath()} component={ProfileOverview} />

      <ProtectedRoute path={kpisPath()} component={KPIController} />
      <ProtectedRoute exact path={genomeConflictsPath()} component={GenomeConflicts} />
      <ProtectedRoute exact path={genomeConflictPath()} component={GenomeConflict} />
      <ProtectedRoute
        exact
        path={profileComparisonPath()}
        component={ProfileComparison}
      />
      {/* Integration testing routes */}
      {global.config.ENABLE_FRANKENSTEIN === 'yes' && (
        <ProtectedRoute path={testCaseCreatePath()} component={NewTestCase} />
      )}
      {global.config.ENABLE_FRANKENSTEIN === 'yes' && (
        <ProtectedRoute path={testCasePath()} component={TestCase} />
      )}
      {global.config.ENABLE_FRANKENSTEIN === 'yes' && (
        <ProtectedRoute path={testCasesPath()} component={TestCases} />
      )}

      <ProtectedRoute
        path={animalSearchResultPath()}
        component={AnimalSearchResultDetail}
      />
      <ProtectedRoute path={animalSearchPath()} component={AnimalSearchResults} />
      <ProtectedRoute path={labOrderPath()} component={LabOrderController} />
      <ProtectedRoute path={labOrdersPath()} component={LabOrders} />
      <ProtectedRoute path={resampleRequestsPath()} component={ResampleRequests} />
      <ProtectedRoute
        path={standaloneAnalysisReportPath()}
        component={StandaloneAnalysisReport}
      />
      <Redirect from='/retrospective' to={workOrdersPath()} />
      <Redirect from='/' to={labOrdersPath()} />
    </Switch>
  </Suspense>
)

const RootRoute = () => <Route path='/' component={Routes} />

export default RootRoute
export {
  animalSearchPath,
  animalSearchResultPath,
  candidateParentExtractPath,
  fulfillmentOverviewHerdAssessmentPath,
  fulfillmentOverviewPath,
  genomeConflictPath,
  genomeConflictsPath,
  kpisPath,
  ktpSignOffPath,
  labOrderHerdAssessmentPath,
  labOrderParentageMatchingPath,
  labOrderPath,
  labOrderProfileConflictPath,
  labOrderRecordedParentagePath,
  labOrdersPath,
  modifySamplePath,
  modifyWorkOrderCostPath,
  profileComparisonPath,
  profileOverviewPath,
  resampleRequestsPath,
  retrospectiveAnalysisPath,
  retrospectiveAnalysisReportPath,
  samplePropertiesPath,
  standaloneAnalysisReportPath,
  testCaseCreatePath,
  testCasePath,
  testCasesPath,
  workOrderCreatePath,
  workOrderHerdAssessmentPath,
  workOrderLabOrderPath,
  workOrderPath,
  workOrdersPath,
}
