import { combineReducers } from 'redux'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import dataReducer from 'lib/reducers/dataReducer'
import { animalScenariosTypes } from 'actions/types'

const initialState = []
const baseType = animalScenariosTypes.FETCH_ANIMAL_SCENARIOS

const reducer = combineReducers({
  data: dataReducer({ initialState, baseType }),
  requestState: requestStateReducer(baseType),
})

export default reducer
