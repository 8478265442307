import React from 'react'
import { hot } from 'react-hot-loader'

import AppBar from 'components/AppBar'
import GlobalNav from '@licnz/react-global-nav'
import Routes from 'components/Routes'
import SentryErrorBoundary from 'lib/components/SentryErrorBoundary'
import TabbedNavigation from 'components/TabbedNavigation'
import ToastNotificationsController from '@licnz/react-toast-notifications'
import VersionManager from 'components/VersionManager'
import { HashRouter as Router } from 'react-router-dom'
import { FeatureTogglesProvider } from 'lib/components/FeatureTogglesProvider/FeatureTogglesProvider'

import 'assets/scss/global'

function AppRoot() {
  return (
    <Router>
      <FeatureTogglesProvider>
        <div>
          <VersionManager />
          <GlobalNav shopLink={global.config.SHOP_FRONTEND_ENDPOINT} />
          <AppBar />
          <TabbedNavigation />
          <ToastNotificationsController />
          <SentryErrorBoundary>
            <Routes />
          </SentryErrorBoundary>
        </div>
      </FeatureTogglesProvider>
    </Router>
  )
}

export default hot(module)(AppRoot)
