import { trimUnderscore } from 'utils/util'

/**
 * This function returns a dynamic length mask with _no_ guides and _no_ leading zeros.
 * i.e. it returns a numeric mask with the same length as the passed in value.
 *
 */
const numericMask = ({ val, maxLength }) => {
  if (!val) return [/[1-9]/]

  let valLength = Number.parseInt(val.replace(/\D/, '')).toString().length
  return valLength
    ? [/[1-9]/, ...Array(Math.min(valLength, maxLength - 1)).fill(/\d/)]
    : []
}

const animalKeyMask = val => numericMask({ val, maxLength: 999 })

const ahbNumberMask = val => {
  const sequenceMaxLength = 999
  const tokens = trimUnderscore(val).split('-')
  let sequenceToken = tokens.length === 3 ? tokens[2] : null

  return [
    ...Array(7).fill(/\d/),
    '-',
    ...Array(2).fill(/\d/),
    '-',
    ...numericMask({
      val: sequenceToken,
      maxLength: sequenceMaxLength,
    }),
  ]
}

const bullCodeMask = val => numericMask({ val, maxLength: 6 })

const eidMask = () => Array(3).fill(/\d/).concat(/\s/).concat(Array(12).fill(/\d/))

const managementNumberMask = val => numericMask({ val, maxLength: 5 })

const numericIdMask = val => numericMask({ val, maxLength: 6 })

const birthIdMask = val => {
  let mask = [
    ...participantCodeMask(val),
    '-',
    ...birthIdYearMask(val),
    '-',
    ...birthIdSequenceMask(val),
  ]

  return mask
}

// eslint-disable-next-line no-unused-vars
const birthIdYearMask = val => {
  return [/\d/, /\d/]
}

const birthIdSequenceMask = val => {
  let mask = [/\d/]
  const maxLength = 5
  const birthIdTokens = trimUnderscore(val).split('-')

  let numberToken = birthIdTokens.length === 3 ? birthIdTokens[2] : null
  if (numberToken) {
    mask = numericMask({ val: numberToken, maxLength })
  }

  return mask
}

/**
 * It's not pretty but this supports a variable length (from `minLength` to `maxLength`),
 * alphabetic participant code. It will allow a single numeric value to be entered after
 * a valid participant code; when a numeric value is entered the alphabetic mask is
 * terminated and the number is not handled (the next section of the mask should take over)
 */
const participantCodeMask = val => {
  let mask = [/[a-zA-Z]/, /[a-zA-Z]/]
  const minLength = 2
  const maxLength = 5
  const birthIdTokens = trimUnderscore(val).split('-')

  let ptptToken = birthIdTokens.length > 0 ? birthIdTokens[0] : null
  let yearToken = birthIdTokens.length > 1 ? birthIdTokens[1] : null

  if (ptptToken) {
    let ptptCode =
      ptptToken.search(/\d/) < 0 ? ptptToken : ptptToken.slice(0, ptptToken.search(/\d/))

    if (ptptCode.length >= minLength) {
      if (
        ptptToken.length !== ptptCode.length ||
        ptptCode.length >= maxLength ||
        !!yearToken
      ) {
        mask = Array(Math.min(ptptCode.length, maxLength)).fill(/[a-zA-Z]/)
      } else {
        mask = [...Array(ptptCode.length).fill(/[a-zA-Z]/), /[a-zA-Z|\d]/]
      }
    }
  }

  return mask
}

// eslint-disable-next-line no-unused-vars
const yearMask = val => {
  return [/\d/, /\d/, /\d/, /\d/]
}

export {
  animalKeyMask,
  ahbNumberMask,
  birthIdMask,
  bullCodeMask,
  eidMask,
  managementNumberMask,
  numericIdMask,
  participantCodeMask,
  yearMask,
}
