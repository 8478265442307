import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import keyCodes from 'constants/keyCodes'
import { isBirthIdWithoutNumber, trimmedBirthId } from 'utils/animalIds'
import {
  ahbNumberMask,
  animalKeyMask,
  birthIdMask,
  bullCodeMask,
  eidMask,
  managementNumberMask,
  numericIdMask,
  participantCodeMask,
  yearMask,
} from './masks'
import { startsWithLetter, startsWithNumber } from 'utils/util'

import MaskedInput from 'react-text-mask'

import styles from './styles'

const MASK_TYPES = {
  AHB_IDENTIFIER: 'ahbIdentifier',
  ANIMAL_KEY: 'animalKey',
  BIRTH_ID: 'birthId',
  BULL_CODE: 'bullCode',
  EID: 'eid',
  MANAGEMENT_NUMBER: 'managementNumber',
  PARTICIPANT_CODE: 'participantCode',
  YEAR: 'year',
}

const MASKS = {
  [MASK_TYPES.ANIMAL_KEY]: animalKeyMask,
  [MASK_TYPES.AHB_IDENTIFIER]: ahbNumberMask,
  [MASK_TYPES.BIRTH_ID]: birthIdMask,
  [MASK_TYPES.BULL_CODE]: bullCodeMask,
  [MASK_TYPES.EID]: eidMask,
  [MASK_TYPES.MANAGEMENT_NUMBER]: managementNumberMask,
  [MASK_TYPES.PARTICIPANT_CODE]: participantCodeMask,
  [MASK_TYPES.YEAR]: yearMask,
}

/**
 * Renders a controlled input field with dynamic masking for animal IDs
 * (supports Birth IDs, Management numbers and Bull codes).
 * Animal ID type is determined by the first character entered:
 *  - number = management number/bull code
 *  - letter = birth id.
 * The mask length is dynamic when entering numeric data
 */
const MaskedAnimalIdInput = ({
  className,
  disabled,
  hasErrors,
  idType,
  inputId,
  onChange,
  onDelete,
  onEnter,
  placeholder,
  value,
}) => {
  useEffect(() => {
    if (value) {
      const input = document.getElementById(inputId)
      if (input && isBirthIdWithoutNumber(input.value)) {
        // set cursor to end of prefill value
        input.setSelectionRange(input.value.length, -1)
      }
    }
  }, [value, inputId])

  const handleKeyUp = e => {
    let currentValue = e.target.value

    if (e.keyCode === keyCodes.DELETE) onDelete()
    if (e.keyCode === keyCodes.ENTER) {
      let animalId =
        currentValue && currentValue.length > 0 ? trimmedBirthId(currentValue) : null
      animalId && onEnter(animalId)
    }
  }

  const getMask = val => {
    let mask = [/[a-zA-Z]|\d/]

    if (startsWithLetter(val)) mask = birthIdMask(val)
    else if (startsWithNumber(val)) mask = numericIdMask(val)

    return mask
  }

  return (
    <MaskedInput
      data-testid='maskedInput'
      className={classnames(styles.animalIdInput, {
        [className]: !!className,
        [styles.error]: hasErrors,
      })}
      guide={!disabled}
      onKeyUp={handleKeyUp}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      mask={idType ? MASKS[idType] : getMask}
      disabled={disabled}
      render={(ref, props) => (
        <input autoComplete='off' ref={ref} {...props} id={inputId} />
      )}
    />
  )
}

MaskedAnimalIdInput.defaultProps = {
  disabled: false,
  hasErrors: false,
  isBirthId: false,
  onChange: () => {},
  onDelete: () => {},
  onEnter: () => {},
}

MaskedAnimalIdInput.propTypes = {
  className: PropTypes.string,
  hasErrors: PropTypes.bool,
  idType: PropTypes.oneOf(Object.values(MASK_TYPES)),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
}

export default MaskedAnimalIdInput
export { MASK_TYPES }
