import React from 'react'
import PropTypes from 'prop-types'

import ButtonAsLink from 'lib/components/ButtonAsLink'
import { PaddedBlock, Section } from '@licnz/react-layout'

import { labOrdersPath } from 'components/Routes'

import styles from './styles'

const UnauthorisedPanel = ({ history, location }) => {
  const handleBack = () => {
    let { state } = location
    history.push(state && state.referer ? state.referer : labOrdersPath())
  }

  return (
    <Section spaced>
      <PaddedBlock>
        <div className={styles.wrapper}>
          <div className={styles.accessDeniedIcon}>
            <svg>
              <title id='svg-id-padlock'>Access Denied</title>
              <use xlinkHref='/images/sprites.svg#padlock' />
            </svg>
            <h1>Access Denied</h1>
          </div>
          <p className={styles.accessDeniedText}>
            Sorry, you do not have permission to access this page. If you think you need
            access, please speak with your team lead or the Systems and Process team.
          </p>
          <div className={styles.buttonWrapper}>
            <ButtonAsLink onClick={handleBack}>
              <svg className={styles.backIcon}>
                <title id='svg-id-arrow-left'>Go back</title>
                <use xlinkHref='/images/sprites.svg#arrow-left' />
              </svg>
              Go back
            </ButtonAsLink>
          </div>
        </div>
      </PaddedBlock>
    </Section>
  )
}

UnauthorisedPanel.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}

export default UnauthorisedPanel
