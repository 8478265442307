export default {
  __schema: {
    directives: [
      {
        args: [
          {
            defaultValue: null,
            description: 'Included when true.',
            name: 'if',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
          },
        ],
        description:
          'Directs the executor to include this field or fragment only when the `if` argument is true.',
        locations: ['FIELD', 'FRAGMENT_SPREAD', 'INLINE_FRAGMENT'],
        name: 'include',
      },
      {
        args: [
          {
            defaultValue: null,
            description: 'Skipped when true.',
            name: 'if',
            type: {
              kind: 'NON_NULL',
              name: null,
              ofType: {
                kind: 'SCALAR',
                name: 'Boolean',
                ofType: null,
              },
            },
          },
        ],
        description:
          'Directs the executor to skip this field or fragment when the `if` argument is true.',
        locations: ['FIELD', 'FRAGMENT_SPREAD', 'INLINE_FRAGMENT'],
        name: 'skip',
      },
    ],
    mutationType: {
      name: 'Mutation',
    },
    queryType: {
      name: 'Query',
    },
    subscriptionType: null,
    types: [
      {
        description: null,
        enumValues: null,
        fields: [
          {
            args: [],
            deprecationReason: null,
            description: null,
            isDeprecated: false,
            name: 'id',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
          },
          {
            args: [],
            deprecationReason: null,
            description: null,
            isDeprecated: false,
            name: 'title',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
          },
        ],
        inputFields: null,
        interfaces: null,
        kind: 'INTERFACE',
        name: 'Filter',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'SelectFilter',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'StringFilter',
            ofType: null,
          },
        ],
      },
      {
        description: 'Sign off information for the lab order',
        enumValues: null,
        fields: null,
        inputFields: null,
        interfaces: null,
        kind: 'UNION',
        name: 'SignOff',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'LabOrderSignOff',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'ParentageSignOff',
            ofType: null,
          },
        ],
      },
      {
        description:
          'A successful response of a lab order or a structured error response',
        enumValues: null,
        fields: null,
        inputFields: null,
        interfaces: null,
        kind: 'UNION',
        name: 'CreateAndReplaceInvestigationsResponse',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'CreateInvestigationsMissingProfilesError',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'CreateInvestigationsResolutionError',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'LabOrder',
            ofType: null,
          },
        ],
      },
      {
        description: null,
        enumValues: null,
        fields: [
          {
            args: [],
            deprecationReason: null,
            description: null,
            isDeprecated: false,
            name: 'title',
            type: {
              kind: 'SCALAR',
              name: 'String',
              ofType: null,
            },
          },
        ],
        inputFields: null,
        interfaces: null,
        kind: 'INTERFACE',
        name: 'ReportableWork',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'ReportableService',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'ReportableTrait',
            ofType: null,
          },
        ],
      },
      {
        description: 'A search result of either a Party, WorkOrder or Reservation',
        enumValues: null,
        fields: null,
        inputFields: null,
        interfaces: null,
        kind: 'UNION',
        name: 'SearchResult',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'Party',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'Reservation',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'WorkOrder',
            ofType: null,
          },
        ],
      },
      {
        description:
          'A successful response of a lab order or a structured error response',
        enumValues: null,
        fields: null,
        inputFields: null,
        interfaces: null,
        kind: 'UNION',
        name: 'CreateAndReplaceAdditionalCandidatesResponse',
        possibleTypes: [
          {
            kind: 'OBJECT',
            name: 'CreateAdditionalCandidatesMissingProfilesError',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'CreateAdditionalCandidatesTypeError',
            ofType: null,
          },
          {
            kind: 'OBJECT',
            name: 'LabOrder',
            ofType: null,
          },
        ],
      },
    ],
  },
}
