import React from 'react'

import {
  genomeConflictsPath,
  kpisPath,
  labOrdersPath,
  profileComparisonPath,
  resampleRequestsPath,
  testCasesPath,
  workOrdersPath,
} from 'components/Routes'

import NavigationTabs from '@licnz/react-nav-tabs'
import { Gutters } from '@licnz/react-layout'

import styles from './styles'

const navLinks = [
  {
    link: labOrdersPath(),
    label: 'Lab orders',
  },
  {
    link: workOrdersPath(),
    label: 'Work orders',
  },
  {
    link: profileComparisonPath({ routeParams: { animalLink: null } }),
    label: 'Profile comparison',
  },
  {
    link: genomeConflictsPath(),
    label: 'Conflicts',
  },
  {
    link: kpisPath({ routeParams: { kpiType: null } }),
    label: 'Performance',
  },
  {
    link: resampleRequestsPath(),
    label: 'Resamples',
  },
]

const frankensteinNavLink = {
  link: testCasesPath(),
  label: 'Frankenstein',
}

const TabbedNavigation = () => {
  const curatedNavLinks =
    global.config.ENABLE_FRANKENSTEIN === 'yes'
      ? [...navLinks, frankensteinNavLink]
      : navLinks

  return (
    <div className={styles.tabsContainer}>
      <Gutters>
        <NavigationTabs navItems={curatedNavLinks} location={location} />
      </Gutters>
    </div>
  )
}

export default TabbedNavigation
