import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { ANIMAL_SEARCH } from 'constants/abilityTypes'
import { animalSearchPath } from 'components/Routes'

import AbilitiesWrapper from 'components/AbilitiesWrapper'
import AnimalLookupForm from 'components/AnimalLookupForm'
import Button from 'lib/components/Button'

import styles from './styles'

const AnimalLookup = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false)

  const containerRef = useRef()

  const handleDocumentClick = useCallback(e => {
    if (containerRef && containerRef.current) {
      if (containerRef.current.contains(e.target)) e.stopPropagation()
      else setIsOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => document.removeEventListener('click', handleDocumentClick)
  }, [handleDocumentClick])

  function handleToggle() {
    setIsOpen(!isOpen)
  }

  function handleSubmit(values) {
    let nonNullParams = { ...values }
    Object.keys(values).forEach(key => values[key] === '' && delete nonNullParams[key])

    window.open(
      `${global.config.DIAGNOSTICS_FRONTEND_ENDPOINT}/#${animalSearchPath({
        queryParams: nonNullParams,
      })}`
    )

    onClose()
  }

  let svgId = isOpen ? 'up' : 'down'

  return (
    <AbilitiesWrapper ability={ANIMAL_SEARCH} showMessage={false}>
      <div ref={containerRef} className={styles.animalLookupContainer}>
        <Button className='buttonPrimary' onClick={handleToggle}>
          Animal lookup
          <svg className={styles.dropdownIcon}>
            <title id={`svg-id-caret-${svgId}`}>Caret {svgId} SVG</title>
            <use xlinkHref={`/images/sprites.svg#caret-${svgId}`} />
          </svg>
        </Button>
        <div className={styles.formContainer}>
          <AnimalLookupForm
            isOpen={isOpen}
            onSubmit={handleSubmit}
            submitButtonText='Search'
          />
        </div>
      </div>
    </AbilitiesWrapper>
  )
}

AnimalLookup.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AnimalLookup
