function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`,
  }
}

const animalScenariosTypes = typesForBaseType('FETCH_ANIMAL_SCENARIOS')

const conflictsTypes = typesForBaseType('FETCH_CONFLICTS')

const idTokenTypes = typesForBaseType('FETCH_ID_TOKEN')

const organisationTypes = typesForBaseType('FETCH_ORGANISATION_FOR_PARTY')

const profileTypes = typesForBaseType('FETCH_PROFILE')

export {
  animalScenariosTypes,
  conflictsTypes,
  idTokenTypes,
  organisationTypes,
  profileTypes,
}
