import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles'

const FormRow = ({
  children,
  className,
  errorDetail,
  helpText,
  inline,
  label,
  showError,
  smallLabel,
}) => {
  let labelClass = smallLabel ? styles.smallLabel : styles.label
  let classes = classnames(styles.formRow, {
    [styles.formRowInline]: inline,
    [className]: className,
  })

  return (
    <div className={classes}>
      {label && (
        <div className={styles.labelWrapper}>
          <label className={labelClass}>{label}</label>
          {helpText && <small className={styles.helpText}>{helpText}</small>}
          {showError && <small className={styles.error}>{errorDetail}</small>}
        </div>
      )}
      {children}
    </div>
  )
}

FormRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  errorDetail: PropTypes.string,
  helpText: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  showError: PropTypes.bool,
  smallLabel: PropTypes.bool,
}

export default FormRow
