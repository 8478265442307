import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles'

const FieldResetButton = ({ label, onReset, value }) => {
  return (
    <button
      className={classnames(styles.resetIcon, {
        [styles.hideResetIcon]: !value,
      })}
      onClick={onReset}
      tabIndex='-1'
      type='reset'
    >
      <span className={styles.a11y}>{label}</span>
    </button>
  )
}

FieldResetButton.propTypes = {
  label: PropTypes.string.isRequired,
  onReset: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default FieldResetButton
