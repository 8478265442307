import React, { useState, useEffect } from 'react'
import axios from 'axios'

import ReactModal from 'react-modal'
import Button from 'lib/components/Button'

import styles from './styles'

const POLL_INTERVAL = 3000
const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

/**
 * This component is responsible for keeping the UI up to date with the latest
 * deployed version. It fetchs a `version.json` file on mount, stores that version
 * in local state, then regularly polls the `version.json` file to check for changes.
 * If a change occurs (i.e. an new version has been deployed), the user is alerted
 * and the browser is refreshed to fetch the latest version.
 */

const VersionManager = () => {
  const [pollingIntervalId, setPollingIntervalId] = useState(null)
  const [currentAppVersion, setCurrentAppVersion] = useState(null)
  const [appIsUpToDate, setAppIsUpToDate] = useState(true)

  useEffect(() => {
    getVersion().then(version => {
      setCurrentAppVersion(version)
    })
  }, [])

  useEffect(() => {
    if (currentAppVersion) {
      let intervalId = setInterval(() => checkVersion(), POLL_INTERVAL)
      setPollingIntervalId(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAppVersion])

  useEffect(() => {
    if (!appIsUpToDate) {
      clearInterval(pollingIntervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appIsUpToDate])

  const getVersion = () => {
    return axios.get('./version.json').then(response => response.data.version)
  }

  const checkVersion = () => {
    return getVersion().then(latestAppVersion => {
      if (currentAppVersion !== latestAppVersion) {
        setAppIsUpToDate(false)
      }
    })
  }

  const refreshBrowser = () => {
    location.reload()
  }

  return (
    <>
      <ReactModal
        isOpen={!appIsUpToDate}
        contentLabel='Outdated Application'
        className={styles.content}
        style={customStyles}
        ariaHideApp={false}
      >
        <p>
          Your LIC Diagnostics application is out of date. We will now refresh your
          browser to update.
        </p>
        <Button className='buttonPrimary buttonBlock' onClick={refreshBrowser}>
          Ok
        </Button>
      </ReactModal>
      <p className={styles.hidden}>{currentAppVersion}</p>
    </>
  )
}

export default VersionManager
