import Decimal from 'decimal.js/decimal'

export function titleCase(string) {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function sentenceCase(string) {
  // A naive implementation only for a single sentence. Only used for labels so this should be ok.
  if (string) {
    let lc = string.toLowerCase()
    return (lc[0].toUpperCase() + lc.slice(1)).split('_').join(' ')
  }
}

export function punctuatedStringList(array) {
  return array.join(', ').replace(/, ([^,]*)$/, ' and $1')
}

export function extractIdFromUrl(url) {
  return /[^/]*$/.exec(decodeURIComponent(url))[0]
}

export function stringAsDecimal(string) {
  return new Decimal(string).toNumber()
}

export function trimUnderscore(str) {
  return str ? str.replace(/_/g, '').trim() : ''
}

export function startsWithLetter(val) {
  return val && val.length && val[0].match(/[a-zA-Z]/)
}

export function startsWithNumber(val) {
  return val && val.length && val[0].match(/\d/)
}

export function formattedPrice(price) {
  return price
    ? new Decimal(Math.abs(price)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : new Decimal(0).toFixed(2)
}

// NOTE: the collection you pass in must be an array of objects that have a `_type` or `type` key
export function findPrice(collection, urn) {
  let foundPrice =
    collection.length > 0 &&
    collection.find(price => {
      return price['_type'] === urn || price['type'] === urn
    })
  return foundPrice
}

export function convertNumbersToRanges(numbers, ranges = []) {
  if (numbers.length === 0) return ranges

  let currentIndex = 0
  let from = numbers[currentIndex]
  let to = from

  while (numbers[currentIndex + 1] === to + 1) {
    currentIndex += 1
    to = numbers[currentIndex]
  }
  ranges.push({ from, to })

  return convertNumbersToRanges(numbers.slice(currentIndex + 1), ranges)
}

export function currentApplication() {
  const baseUrl = window.location.protocol + '//' + window.location.host
  switch (baseUrl) {
    case global.config.SERVICE_PLANNING_FRONTEND_ENDPOINT:
      return 'planning'
    case global.config.SAMPLE_RECEIPTING_FRONTEND_ENDPOINT:
      return 'receipting'
    case global.config.DIAGNOSTICS_FRONTEND_ENDPOINT:
      return 'analysis'
    default:
      return null
  }
}

export function parseURNIdentifier(urn) {
  // Parses and sentencizes the identifier (the last part) of a URN
  let splitUrn = urn.split(':')
  return sentenceCase(splitUrn[splitUrn.length - 1])
}

// https://github.com/apollographql/apollo-client/pull/3892 - allows us to remove the 'GraphQL Error' text from errors
export function parseGraphQLError(string) {
  return string.replace(/GraphQL error: /gi, '')
}
