const AHB_NUMBER_REGEX = /^\d{7}-(?:(\d{2}-)?)\d+$/ // searching AHBs allows for an optional year - receipting them in does not
const ANIMAL_KEY_REGEX = /^-?\d+$/
const BARCODE_REGEX = /^[a-zA-Z]\d{3,12}$/
const BIRTHPACK_REGEX = /^[a-zA-Z]{2,5}\d{3,7}$/
const BIRTH_ID_PARTICIPANT_CODE_AND_YEAR_REGEX = /^[a-zA-Z]{2,5}-\d{2}$/
const BIRTH_ID_REGEX = /^[a-zA-Z]{2,5}-\d{2}-[1-9]\d{0,4}$/ // e.g. PTPTP-12-12345
const BULL_CODE_REGEX = /^[1-9]\d{0,5}$/ // 1-6 digits, no leading zeroes
const MANAGEMENT_NUMBER_REGEX = /^[1-9]\d{0,4}$/ // 1-5 digits, no leading zeroes

const isAHBNumber = id => !!(id && id.match(AHB_NUMBER_REGEX))

const isAnimalKey = id => !!(id && id.match(ANIMAL_KEY_REGEX))

const isBirthId = id => !!(id && id.match(BIRTH_ID_REGEX))

const isBullCode = id => !!(id && id.match(BULL_CODE_REGEX))

const isManagementNumber = id => !!(id && id.match(MANAGEMENT_NUMBER_REGEX))

const isBarcode = id => !!(id && id.match(BARCODE_REGEX))
const isBirthpack = id => !!(id && id.match(BIRTHPACK_REGEX))
const isSampleId = id => isBarcode(id) || isBirthpack(id)

const trimmedBirthId = rawAnimalId => {
  return rawAnimalId.replace(/_/g, '').replace(/ /g, '').toUpperCase()
}

const isBirthIdWithoutNumber = id =>
  id && id.match(BIRTH_ID_PARTICIPANT_CODE_AND_YEAR_REGEX)

export {
  isAHBNumber,
  isAnimalKey,
  isBirthId,
  isBirthIdWithoutNumber,
  isBullCode,
  isManagementNumber,
  isSampleId,
  trimmedBirthId,
}
