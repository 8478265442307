import { createSelector } from 'reselect'

const IN_PROGRESS = 'in progress'
const FAILURE = 'failure'
const SUCCESS = 'success'

const getRequestStatus = createSelector(
  [(requestState) => requestState],
  (requestState) => {
    let status = null

    if(requestState.fetching) {
      status = IN_PROGRESS
    }
    if(requestState.fetched) {
      status = requestState.errors ? FAILURE : SUCCESS
    }

    return status
  }
)

export {
  getRequestStatus,
  IN_PROGRESS,
  FAILURE,
  SUCCESS,
}
