const ADMINISTER_JOBS = 'urn:lic:ability:lab_fulfillment:administer_parentage_jobs'
const ANIMAL_SEARCH = 'urn:lic:ability:lab_fulfillment:animal_search'
const CONFLICT_ACTIONS = 'urn:lic:ability:lab_fulfillment:conflict_actions'
const FRANKENSTEIN = 'urn:lic:ability:lab_fulfillment:frankenstein'
const HERD_ASSESSMENT = 'urn:lic:ability:lab_fulfillment:herd_assessment'
const ID_CHANGES = 'urn:lic:ability:lab_fulfillment:id_changes'
const LAB_ORDER_ACTIONS = 'urn:lic:ability:lab_fulfillment:lab_order_actions'
const MODIFY_COST = 'urn:lic:ability:lab_fulfillment:modify_cost'
const MODIFY_SUBMISSION = 'urn:lic:ability:lab_fulfillment:modify_submission'
const ORDER_RETROSPECTIVE_PRODUCTS = 'urn:lic:ability:inventory:retrospective_products'
const PROFILE_COMPARISON = 'urn:lic:ability:lab_fulfillment:profile_comparison'
const RESAMPLE_ACTIONS = 'urn:lic:ability:lab_fulfillment:resample_actions'
const TEST_MANAGEMENT = 'urn:lic:ability:lab_fulfillment:test_management'
const TRAIT_ANALYSIS = 'urn:lic:ability:lab_fulfillment:trait_analysis'
const TRAIT_MANAGEMENT = 'urn:lic:ability:lab_fulfillment:trait_management'
const VIEW_ANIMAL_PROFILES = 'urn:lic:ability:lab_fulfillment:animal_profiles'
const VIEW_CONFLICTS = 'urn:lic:ability:lab_fulfillment:conflicts'
const VIEW_DIAGNOSTICS_OVERVIEW = 'urn:lic:ability:lab_fulfillment:diagnostics_overview'
const VIEW_DOCUMENTS = 'urn:lic:ability:lab_fulfillment:documents'
const VIEW_KPIS = 'urn:lic:ability:data_face:kpi'
const VIEW_LAB_ORDERS = 'urn:lic:ability:lab_fulfillment:lab_orders'
const VIEW_RESAMPLE_REQUESTS = 'urn:lic:ability:lab_fulfillment:resamples'
const VIEW_WORK_ORDERS = 'urn:lic:ability:lab_fulfillment:work_orders'
const VIEW_WORK_ORDER_ACTIONS = 'urn:lic:ability:lab_fulfillment:work_order_actions'

const ALL_ABILITIES = [
  ADMINISTER_JOBS,
  ANIMAL_SEARCH,
  CONFLICT_ACTIONS,
  FRANKENSTEIN,
  HERD_ASSESSMENT,
  ID_CHANGES,
  LAB_ORDER_ACTIONS,
  MODIFY_COST,
  MODIFY_SUBMISSION,
  ORDER_RETROSPECTIVE_PRODUCTS,
  PROFILE_COMPARISON,
  RESAMPLE_ACTIONS,
  TEST_MANAGEMENT,
  TRAIT_ANALYSIS,
  TRAIT_MANAGEMENT,
  VIEW_ANIMAL_PROFILES,
  VIEW_CONFLICTS,
  VIEW_DIAGNOSTICS_OVERVIEW,
  VIEW_DOCUMENTS,
  VIEW_KPIS,
  VIEW_LAB_ORDERS,
  VIEW_RESAMPLE_REQUESTS,
  VIEW_WORK_ORDERS,
  VIEW_WORK_ORDER_ACTIONS,
]

export {
  ADMINISTER_JOBS,
  ALL_ABILITIES,
  ANIMAL_SEARCH,
  CONFLICT_ACTIONS,
  FRANKENSTEIN,
  HERD_ASSESSMENT,
  ID_CHANGES,
  LAB_ORDER_ACTIONS,
  MODIFY_COST,
  MODIFY_SUBMISSION,
  ORDER_RETROSPECTIVE_PRODUCTS,
  PROFILE_COMPARISON,
  RESAMPLE_ACTIONS,
  TEST_MANAGEMENT,
  TRAIT_ANALYSIS,
  TRAIT_MANAGEMENT,
  VIEW_ANIMAL_PROFILES,
  VIEW_CONFLICTS,
  VIEW_DIAGNOSTICS_OVERVIEW,
  VIEW_DOCUMENTS,
  VIEW_KPIS,
  VIEW_LAB_ORDERS,
  VIEW_RESAMPLE_REQUESTS,
  VIEW_WORK_ORDERS,
  VIEW_WORK_ORDER_ACTIONS,
}
