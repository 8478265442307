import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { trimUnderscore } from 'utils/util'

import Button from 'lib/components/Button'
import FieldResetButton from 'lib/components/FieldResetButton'
import FormRow from 'lib/components/FormRow'
import MaskedAnimalIdInput from 'lib/components/MaskedAnimalIdInput'
import { Form, Formik, Field } from 'formik'
import { PaddedBlock } from '@licnz/react-layout'
import { MASK_TYPES } from 'lib/components/MaskedAnimalIdInput'

import styles from './styles'

const SEARCH_PARAMS_MAPPING = {
  ahbIdentifier: 'AHB number',
  animalKey: 'Animal key',
  birthId: 'Birth ID',
  bullCode: 'Bull code',
  eid: 'EID',
  managementNumber: 'Management number',
  participantCode: 'PTPT',
  sampleId: 'Sample ID',
}

const INITIAL_VALUES = {
  ahbIdentifier: '',
  animalKey: '',
  birthId: '',
  eid: '',
  bullCode: '',
  managementNumber: '',
  participantCode: '',
  sampleId: '',
}

const AnimalLookupForm = ({ isOpen, onSubmit, submitButtonText }) => {
  /**
   * NOTE: As the masked input can return form values with a trailing underscore/s, we loop through all the
   * form values and use `trimUnderscore` to remove them before passing them to the `onSubmit` function.
   */
  const handleSubmit = values => {
    let newValues = {}
    Object.entries(values).forEach(
      ([key, value]) => (newValues[key] = trimUnderscore(value))
    )
    onSubmit(newValues)
  }

  const FormRowWithFormField = ({
    disabled,
    field: { name, onChange, value },
    onCustomChange,
    onReset,
  }) => {
    const handleChange = onCustomChange || onChange

    const sharedProps = {
      className: styles.input,
      disabled: disabled,
      onChange: handleChange,
      type: 'text',
      value: value,
    }

    return (
      <FormRow className={styles.formRow}>
        <label className={styles.label}>
          <span htmlFor={name}>{SEARCH_PARAMS_MAPPING[name]}</span>
          {Object.values(MASK_TYPES).includes(name) ? (
            <MaskedAnimalIdInput {...sharedProps} idType={name} inputId={name} />
          ) : (
            <input {...sharedProps} name={name}></input>
          )}
          <FieldResetButton
            label={`Reset ${SEARCH_PARAMS_MAPPING[name]}`}
            onReset={onReset}
            value={value}
          />
        </label>
      </FormRow>
    )
  }

  const InlineFormField = ({
    disabled,
    field: { name, onChange, value },
    onCustomChange,
    onReset,
  }) => {
    const handleChange = onCustomChange || onChange

    return (
      <>
        <label className={styles.label}>
          <span htmlFor={name}>{SEARCH_PARAMS_MAPPING[name]}</span>
          <MaskedAnimalIdInput
            className={styles.inlineInput}
            disabled={disabled}
            idType={name}
            inputId={name}
            onChange={handleChange}
            type='text'
            value={value}
          />
          <FieldResetButton
            label={`Reset ${SEARCH_PARAMS_MAPPING[name]}`}
            onReset={onReset}
            value={value}
          />
        </label>
      </>
    )
  }

  return isOpen ? (
    <div className={styles.animalLookupForm}>
      <PaddedBlock>
        <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
          {({ handleReset, isSubmitting, setFieldValue, values }) => {
            const isFieldDisabled = name => {
              if (isSubmitting) return true

              let populatedFields = Object.keys(values).filter(key => values[key] !== '')
              if (!populatedFields.length) return false

              if (
                name === 'participantCode' &&
                populatedFields.includes('managementNumber')
              ) {
                return false
              } else if (
                name === 'managementNumber' &&
                populatedFields.includes('participantCode')
              ) {
                return false
              } else if (!populatedFields.includes(name)) return true
            }

            const upCase = name => e => {
              let upperCaseValue = e.target.value.toUpperCase()
              setFieldValue(name, upperCaseValue, false)
            }

            const handleResetField = name => () => {
              setFieldValue(name, '')
            }

            return (
              <Form>
                <FormRow className={styles.formRow}>
                  <div className={styles.flexWrapper}>
                    <Field
                      component={InlineFormField}
                      disabled={isFieldDisabled('participantCode')}
                      name='participantCode'
                      onCustomChange={upCase('participantCode')}
                      onReset={handleResetField('participantCode')}
                    />
                    <Field
                      component={InlineFormField}
                      disabled={isFieldDisabled('managementNumber')}
                      name='managementNumber'
                      onReset={handleResetField('managementNumber')}
                    />
                  </div>
                </FormRow>
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('birthId')}
                  name='birthId'
                  onCustomChange={upCase('birthId')}
                  onReset={handleResetField('birthId')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('animalKey')}
                  name='animalKey'
                  onReset={handleResetField('animalKey')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('bullCode')}
                  name='bullCode'
                  onReset={handleResetField('bullCode')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('eid')}
                  name='eid'
                  onReset={handleResetField('eid')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('ahbIdentifier')}
                  name='ahbIdentifier'
                  onReset={handleResetField('ahbIdentifier')}
                />
                <Field
                  component={FormRowWithFormField}
                  disabled={isFieldDisabled('sampleId')}
                  name='sampleId'
                  onCustomChange={upCase('sampleId')}
                  onReset={handleResetField('sampleId')}
                />
                <div className={styles.buttonWrapper}>
                  <a onClick={handleReset}>Reset</a>
                  <Button className='buttonSecondary' type='submit'>
                    {submitButtonText}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </PaddedBlock>
    </div>
  ) : null
}

AnimalLookupForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
}

export default withRouter(AnimalLookupForm)
